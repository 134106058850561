<template>
  <div class="products">
    <v-container fluid>
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        @productsCount="updateCount"
      >
        <template #category-block-position-1>
          <category-block
            :target="category"
            position="position1"
            class="category-block category-block-1"
            :container="false"
          />
        </template>
        <template #category-block-position-3>
          <category-block
            :target="category"
            position="position3"
            class="category-block category-block-3"
            :container="false"
          />
        </template>
        <template #category-block-position-4>
          <category-block
            :target="category"
            position="position4"
            class="category-block category-block-4"
            :container="false"
          />
        </template>
      </ProductListGrid>
    </v-container>
    <div class="white product-slider-wrapper">
      <v-container>
        <ProductListSlider
          :layout="27"
          :categoryId="category.categoryId"
          :title="'I più venduti in ' + category.name"
          :paginationClass="'swiper-pagination-venduti'"
          :showArrows="$vuetify.breakpoint.smAndUp"
        />
      </v-container>
    </div>
    <div v-if="footerText">
      <v-container><div v-html="footerText"/></v-container>
    </div>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";
import categoryMixins from "~/mixins/category";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid
  },
  data() {
    return { count: 0 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  footerText() {
    return this.$ebsn.meta(
      this.category,
      "metaData.category_info.FOOTER_TEXT",
      null
    );
  },
  metaInfo() {
    return {
      title: this.$ebsn.meta(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category.name
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$ebsn.meta(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.category.name
          )
        }
      ]
    };
  }
};
</script>
